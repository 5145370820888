import { defineStore } from "pinia";

export const useAppStore = defineStore({
  id: "app",
  // 一个返回新状态的函数
  state: () => ({
    loadingVisible: false,

    drawer: true,
    mini: true,

    currentStockDetail: {
      stock_code: "600388.SH",
      stock_name: "龙净环保",
      code_type: "stock",
    },

    logData: "",

    backTestData: null,
    firmBackTestData: null,

    currentFirmDetail: null,
  }),
  getters: {},
  actions: {},
});
