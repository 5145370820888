import Request from "@/components/request/js/index.js";

export default {
  //更改api地址
  changeApi(url) {
    Request().setBaseUrl(url);
  },
  //登录
  login(name, password) {
    return Request().post("/api/auth/login", {
      header: {
        contentType: "application/json",
      },
      data: {
        name: name,
        password: password,
      },
    });
  },

  //更改密码
  changePassword(name, old_password, password) {
    return Request().post("/api/user/password", {
      header: {
        contentType: "application/json",
      },
      data: {
        name: name,
        old_password: old_password,
        password: password,
      },
    });
  },
};
