<template>
  <div></div>
</template>
<script>
import PreviewPdf from '@/components/Preview/PreviewPdf.vue';
import PreviewIframe from '@/components/Preview/PreviewIframe.vue';
import Viewer from 'viewerjs';

import Bus from '@/components/Bus/bus.js';

export default {
  name: 'Preview',
  props: {},
  components: {
    PreviewPdf,
  },
  data() {
    return {
      fileType: {
        video: ['mp4'],
        image: ['png', 'jpeg', 'jpg', 'gif', 'bmp'],
        audio: ['mp3', 'wav', 'ogg', 'aac'],
        text: ['txt', 'md'],
        zip: ['zip', 'rar', '7z', 'tar', 'iso'],
        word: ['doc', 'docx', 'docm'],
        ppt: ['pptx', 'pptm', 'pptx'],
        excel: ['xlsx', 'xls', 'xlsm'],
        web: ['html', 'htm'],
        code: [
          'php',
          'js',
          'css',
          'py',
          'go',
          'vue',
          'java',
          'ini',
          'config',
          'cpp',
          'c',
          'ts',
          'h',
          'cs',
          'sql',
          'scss',
          'xml',
          'html',
          'htm',
        ],
        pdf: ['pdf'],
        ai: ['ai'],
        design: ['psd', 'xcf', 'sketch', 'xd', 'cdr'],
        cad: ['cad'],
      },
    };
  },
  computed: {},
  methods: {
    //获取文件后缀
    getTail(dataName) {
      var arr = dataName.split('.'); //通过\分隔字符串，成字符串数组
      return arr[arr.length - 1].trim().toLowerCase(); //取最后一个，就是文件扩展名
    },

    preview(url, fileName) {
      let tail = this.getTail(fileName).toLowerCase();
      let type = 'default';

      for (let key in this.fileType) {
        if (this.fileType[key].indexOf(tail) != -1) {
          type = key;
        }
      }

      switch (type) {
        case 'word':
          this.previewOffice(encodeURIComponent(url), fileName);
          break;
        case 'excel':
          this.previewOffice(encodeURIComponent(url), fileName);
          break;
        case 'ppt':
          this.previewOffice(encodeURIComponent(url), fileName);
          break;
        case 'pdf':
          this.previewPdf(url, fileName);
          break;
        case 'image':
          this.previewImage(url, fileName);
          break;
        default:
          this.$swal.fire('抱歉', '网页端暂不支持本文件格式预览！', 'error');
          break;
      }
    },

    previewOffice(url, name) {
      this.$layer.iframe({
        content: {
          content: PreviewIframe, //传递的组件对象
          parent: this, //当前的vue对象
          data: {
            //iframeData: {url:`https://view.officeapps.live.com/op/view.aspx?src=${url}`}
            iframeData: {
              url: `https://view.officeapps.live.com/op/embed.aspx?src=${url}`,
            },
          },
        },
        area: ['80vw', '80vh'],
        title: 'Office预览-' + name,
        maxmin: true,
        resize: true,
        shade: false,
      });
    },
    previewPdf(url, name) {
      this.$layer.iframe({
        content: {
          content: PreviewPdf, //传递的组件对象
          parent: this, //当前的vue对象
          data: {
            pdfUrl: `${url}`,
          },
        },
        area: ['80vw', '80vh'],
        title: 'PDF预览-' + name,
        maxmin: true,
        resize: true,
        shade: false,
      });
    },
    previewImage(url, name) {
      let image = document.getElementById('hideViewer');
      image.src = url;
      let viewer = new Viewer(image, {});
      image.addEventListener('hide', function () {
        viewer.destroy();
      });
      viewer.show();
    },
  },
};
</script>
<style></style>
