import Vue from "vue";
import File from "@/api/file.js";
import Bus from "@/components/Bus/bus.js";
import LogPreview from "@/components/Preview/LogPreview";
import router from "@/router";
import axios from "axios";

const downloadFile = function (id) {
  Vue.prototype.showLoading("下载中，请稍候");
  File.downloadFile(id)
    .then((res) => {
      window.open(res.data.url);
    })
    .catch((err) => {
      console.log(err.msg);
    })
    .finally(() => {
      Vue.prototype.hideLoading();
    });
};

const previewFile = function (id) {
  Vue.prototype.showLoading("获取文件中，请稍候");
  File.downloadFile(id)
    .then((res) => {
      Bus.$emit("previewFile", { url: res.data.url, name: res.data.name });
    })
    .catch((err) => {
      console.log(err.msg);
    })
    .finally(() => {
      Vue.prototype.hideLoading();
    });
};

const getPermissions = function (permission) {
  //console.log(Vue.prototype.userStore);
  if (Vue.prototype.userStore) {
    let permissions = Vue.prototype.userStore.permissions;
    if (permissions.indexOf(permission) != -1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};
const getRoles = function (roles) {
  //console.log(Vue.prototype.userStore);
  if (Vue.prototype.userStore) {
    let haveRoles = Vue.prototype.userStore.roles;
    let res = false;
    roles.forEach((role) => {
      if (haveRoles.indexOf(role) != -1) {
        res = true;
      }
    });
    return res;
  } else {
    return false;
  }
};

const showLog = function (data, _this) {
  let windowHandel = Vue.prototype.$layer.iframe({
    content: {
      content: LogPreview, //传递的组件对象
      parent: _this, //当前的vue对象
      data: {
        iframeData: {
          data: data,
        },
      },
    },
    area: ["1280px", "720px"],
    title: "回测实时日志",
    shade: false,
    maxmin: false, //开启最大化最小化
    resize: false, //是否允许拉伸，默认是不允许
  });
  return windowHandel;
};

const handelLog = function (res, _this) {
  let logStr = "";
  res.data.logs.forEach((item) => {
    let str = item.replace(/\n/g, "<br>");
    str = str.replace(
      "| INFO     |",
      "<span style='color: #2AA49D'>| INFO  |</span>"
    );
    str = str.replace(
      "| ERROR    |",
      "<span style='color: #EF534F'>| ERROR |</span>"
    );
    str = str.replace("api.utils.logger:info:44 -", "");
    let regex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}\.\d{3}/;
    let match = str.match(regex);
    if (match) {
      str = str.replace(
        match[0],
        "<span style='color: #0080ff'>" + match[0] + "</span>"
      );
    }

    logStr += str;
  });
  _this.appStore.logData = logStr;
};

export default {
  downloadFile,
  previewFile,
  getPermissions,
  getRoles,
  showLog,
  handelLog,
};
