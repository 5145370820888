import router from "@/router/index.js";
import Vue from "vue";
import moment from "moment";
const getPercent = function (val) {
  return (val * 100).toFixed(2) + "%";
};
const getColor = function (val) {
  if (val > 0) {
    return "color:red;";
  } else if (val < 0) {
    return "color:green;";
  } else {
    return "";
  }
};
const getDateColor = function (val) {
  if (val) {
    let today = moment().format("YYYY-MM-DD");

    if (val == today) {
      return {
        textDecoration: "underline",
      };
    } else {
      return {
        textDecoration: "none",
      };
    }
  } else {
    return {
      textDecoration: "none",
    };
  }
};
const getDirectionColor = function (val) {
  if (val == 1) {
    return "color:red;";
  } else {
    return "color:green;";
  }
};
const openStockDetail = function (data = null) {
  if (data) {
    Vue.prototype.appStore.currentStockDetail = {
      stock_code: data.stock_code,
      stock_name: data.stock_name,
      code_type: data.code_type,
    };
  }
  if (router.history.current.name != "股票详情") {
    router.push({
      name: "股票详情",
    });
  }
};

export default {
  getPercent,
  getColor,
  getDateColor,
  getDirectionColor,
  openStockDetail,
};
