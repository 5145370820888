<template>
  <div style="width: 100%; height: 100%">
    <div
      class="logDiv"
      ref="logDiv"
      v-html="appStore.logData"
      style="width: 100%; height: 100%; overflow-y: scroll"
    ></div>
  </div>
</template>
<script>
import axios from "axios";
import Bus from "@/components/Bus/bus.js";
let editor = null;
export default {
  props: {
    layerid: {
      //自动注入的layerid
      type: String,
      default: "",
    },
    iframeData: {
      //传递的数据
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {};
  },
  mounted() {
    this.editorShow = false;
    this.editorShow = true;
    this.getLog();

    Bus.$on("closeLogWindow", (data) => {
      this.cancel();
    });
  },
  beforeDestroy() {
    Bus.$off("closeLogWindow");
  },
  computed: {},
  methods: {
    getLog() {},
    onEditorMounted(thisEditor) {
      editor = thisEditor;
    },
    cancel() {
      this.$layer.close(this.layerid);
    },
  },
  watch: {
    "appStore.logData": {
      handler: (val, oldVal) => {
        //logDiv滚动到最底部
        let logDiv = document.getElementsByClassName("logDiv")[0];
        logDiv.scrollTop = logDiv.scrollHeight;
      },
      deep: true,
    },
  },
};
</script>
<style>
.logDiv {
  background-color: #000000;
  color: #bfbfbf;
  padding: 10px;
}
</style>
