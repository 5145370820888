<template>
  <!-- <fade-transition> -->
  <div
    v-show="loadingVisible"
    style="
      position: absolute;
      top: 50%;
      left: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <div
      @touchmove.prevent
      @mousewheel.prevent
      style="
        position: fixed;
        background-color: #000000;
        height: 100vh;
        width: 100vw;
        z-index: 9999998;
        opacity: 0.5;
        padding: 0;
      "
    ></div>
    <div
      @touchmove.prevent
      @mousewheel.prevent
      style="
        opacity: 0.95;
        border-radius: 20px;
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 180px;
        background-color: #ffffff;
        z-index: 9999999;
        padding: 5px;
      "
    >
      <div class="loading loading0" style="left: 30px; top: 70px"></div>
      <div class="loading loading1" style="left: 80px; top: 70px"></div>
      <div class="loading loading2" style="left: 130px; top: 70px"></div>

      <div style="margin-top: 80px; text-align: center; color: #3f51b5">
        {{ content }}
      </div>
    </div>
  </div>
  <!-- </fade-transition> -->
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import Vue from "vue";
// import { mapStores } from 'pinia'
// import { useAppStore } from '@/store/app'

export default {
  name: "loading",
  components: {
    FadeTransition,
  },
  props: {},
  mounted() {
    Vue.prototype.showLoading = (content = "加载中，请稍候") => {
      this.loadingVisible = true;
      this.content = content;
    };
    Vue.prototype.hideLoading = () => {
      //console.trace();
      this.loadingVisible = false;
    };
  },
  data() {
    return {
      loadingVisible: false,
      content: "加载中，请稍候",
    };
  },
  methods: {},
  computed: {
    //...mapStores(useAppStore),
  },
};
</script>
<style scoped="scoped">
.loading {
  position: absolute;
  width: 50px;
  perspective: 200px;
}

.loading0:after {
  position: absolute;
  width: 30px;
  height: 30px;
  content: "加";
  padding: 4px 6px;
  color: #fff;
  animation: jumping 0.5s infinite alternate;
  background: #3f51b5;
}

.loading1:after {
  position: absolute;
  width: 30px;
  height: 30px;
  content: "载";
  padding: 4px 6px;
  color: #fff;
  animation: jumping 0.5s infinite alternate;
  background: #3f51b5;
  animation-delay: 200ms;
}
.loading2:after {
  position: absolute;
  width: 30px;
  height: 30px;
  content: "中";
  padding: 4px 6px;
  color: #fff;
  animation: jumping 0.5s infinite alternate;
  background: #3f51b5;
  animation-delay: 400ms;
}

@keyframes jumping {
  0% {
    transform: scale(1) translateY(0px) rotateX(0deg);
    box-shadow: 0 0 0 #3f51b5;
  }

  100% {
    transform: scale(1.2) translateY(-25px) rotateX(45deg);
    background: #3f51b5;
    box-shadow: 0 25px 40px #000;
  }
}
</style>
