// Imports
import Vue from "vue";
import Router from "vue-router";
import { layout, route } from "@/util/routes";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash };
    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
  routes: [
    // {
    // 	path: '*',
    // 	redirect: '/error',
    // 	name: 'error'
    // },

    {
      path: "/",
      redirect: "/home",
      name: "defaultRoute",
    },

    // layout('Page', [
    //   route('帐号登录', {default:'Login'}, 'login'),
    // ]),
    layout("AuthIllustrationLayout", [
      route("登录", { default: "Login" }, "login"),
      route("更改密码", { default: "ChangePwd" }, "ChangePwd"),
    ]),

    layout("DashboardLayout", [
      route("主页", { default: "Dashboard/Dashboard" }, "home", {
        keepAlive: true,
        canBack: false,
      }),
      route("自选池", { default: "Pool" }, "Pool", {
        keepAlive: true,
        canBack: false,
      }),
      route("组合策略", { default: "Firm" }, "Firm", {
        keepAlive: true,
        canBack: false,
      }),
      route("轮动策略", { default: "Rotation" }, "Rotation", {
        keepAlive: true,
        canBack: false,
      }),
      route("数据仓库", { default: "DataStore" }, "DataStore", {
        keepAlive: true,
        canBack: false,
      }),
      route("论文仓库", { default: "PaperStore" }, "PaperStore", {
        keepAlive: true,
        canBack: false,
      }),

      route("股票详情", { default: "StockDetail" }, "StockDetail", {
        keepAlive: true,
        canBack: true,
      }),
      route("组合详情", { default: "FirmDetail" }, "FirmDetail/:id", {
        keepAlive: false,
        canBack: true,
      }),
    ]),
  ],
});

router.beforeResolve((to, from, next) => {
  let showAlert = () => {
    Vue.swal({
      icon: "error",
      title: "提示",
      text: "抱歉您没有该权限",
    });
  };
  //console.log(to.meta.permission,Vue.prototype.userStore);
  if (
    to.name != "登录" &&
    to.name != "更改密码" &&
    to.name != "打印" &&
    to.name != "打印标签" &&
    to.name != "打印页面" &&
    to.name != "打印MD"
  ) {
    if (!Vue.prototype.userStore) {
      return next({ name: "登录" });
    }

    //console.log(to.meta.permission,Vue.prototype.$commonHelpers.getPermissions(to.meta.permission));
    // if (!Vue.prototype.$commonHelpers.getPermissions(to.meta.permission)) {
    //   showAlert();
    //   //return router.back();
    //   return next({ name: "登录" });
    //   //return false;
    // }
  }

  return next();
});

export default router;
